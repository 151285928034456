import React, { useEffect, useState } from "react";
import CommonNavBar from "views/components/CommonNavBar";
import { useNavigate } from "react-router-dom";
import styles from "./ClassHourRecord.module.less";
import {getUserProductInfo, myProductList} from "api/mine";
import InfiniteScroll from 'react-infinite-scroller';

function ClassHourRecord() {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataList, setDataList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [productInfo, setProductInfo] = useState({});

    const getProductInfo = ()=>{
        getUserProductInfo().then(res => {
            if(res.code == 200){
                console.log(res.data);
                setProductInfo(res.data);
            }
        });
    }

    const loadMoreData = () => {
        myProductList(currentPage,pageSize).then(res => {
            if(res.code == 200){
                if(res.data.length>0){
                    setHasMore(true);
                    setCurrentPage(currentPage + 1);
                    setDataList(dataList.concat(res.data));
                }else{
                    setHasMore(false);
                }
            }
        });
    }

    useEffect(()=>{
        getProductInfo();
        loadMoreData();
    },[currentPage]);

    return (
        <div className={styles["main-container"]}>
            <CommonNavBar title={"课时记录"} onClickBack={() => {
                navigate(-1);
            }}
            />
            <img
                className={styles["main-container-banner"]}
                src={require("@/images/mine/img_integration_top_bg.png")}
            />
            <div className={styles["classHour"]}>
                <div className={styles["classHour-item"]}>
                    <div className={styles["item-hour"]}>
                        <span className={styles["item-hour-text"]}>
                         {productInfo["total"]}
                        </span>
                    </div>
                    <div className={styles["item-desc"]}>缴费课时</div>
                </div>
                <div className={styles["classHour-item"]}>
                    <div className={styles["item-hour"]}>
                        <span className={styles["item-hour-text"]}>
                          {productInfo["usage"]}
                        </span>
                    </div>
                    <div className={styles["item-desc"]}>消耗课时</div>
                </div>
                <div className={styles["classHour-item"]}>
                    <div className={styles["item-hour"]}>
                        <div className={styles["item-hour-integral"]}>{productInfo["awaiting"]}</div>
                    </div>
                    <div className={styles["item-desc"]}>剩余课时</div>
                </div>
            </div>
            <div className={styles["class-record"]}>
                <InfiniteScroll
                    pageStart={currentPage} // 设置初始化请求的页数
                    loadMore={loadMoreData}
                    hasMore={hasMore} // 是否继续监听滚动事件 true 监听 | false 不再监听
                    >
                    {
                        dataList.map((item, index) => {
                            return (
                                <div className={styles["class-record-list"]} key={index}>
                                    <div className={styles["class-record-list-item1"]}>
                                        <div className={styles["class-record-list-item1-text1"]}>{item.title}</div>
                                        <div className={styles["class-record-list-item1-text2"]}>{item.state}</div>
                                    </div>
                                    <div className={styles["class-record-list-item2"]}>
                                        <div></div>
                                        <div>{item.trigger_date}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default ClassHourRecord;
