import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./Level.module.less";
import { getMaterialBook } from "api/textbook";
import { setFavoriteCourseware } from "api/materials";
import { debounce } from "lodash";
import { Tabs, Toast } from "react-vant";
import ProportionIcon from "views/components/ProportionIcon";
import CommonModal from "views/components/CommonModal";
const Level = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const loadingRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [title, setTitle] = useState("");
  const [dataList, setDataList] = useState([]);
  const [lockShow, setLockShow] = useState(false);
  const loadData = async () => {
    let id = null;
    try {
      id = searchParams.get("id");
      if (searchParams.get("active")) {
        setDefaultIndex(Number(searchParams.get("active")));
        setActiveIndex(Number(searchParams.get("active")));
      }
    } catch (e) {
      Toast.info("暂无数据，请返回重试");
    }
    if (id !== null) {
      setIsLoading(true);
      const res = await getMaterialBook(id);
      setIsLoading(false);
      if (res.code === 200) {
        if (Array.isArray(res.data?.data)) {
          setDataList(res.data.data);
        }
        setTitle(res.data.title || "暂无教材数据");
      } else {
        setTitle("暂无数据");
        Toast.info("暂无数据，请返回重试");
      }
    } else {
      Toast.info("暂无数据，请返回重试");
    }
  };
  const handleFavorite = debounce(
    async (item) => {
      if (!!!localStorage.getItem("access_token")) {
        navigate("/login/logon");
        return;
      }
      if (item.favorite) {
        loadingRef.current = Toast.loading({
          message: "正在取消收藏...",
          forbidClick: true,
          duration: 0
        });
      } else {
        loadingRef.current = Toast.loading({
          message: "正在收藏...",
          forbidClick: true,
          duration: 0
        });
      }
      const res = await setFavoriteCourseware({
        courseware_id: item.grade_id
      });
      loadingRef.current.clear();
      if (res.code === 200) {
        Toast.info(res.message);
        item.favorite = !item.favorite;
        setDataList([...dataList]);
      }
    },
    500,
    {
      leading: true,
      trailing: false
    }
  );
  const handleDetail = (item) => {
    if (item.is_locked === 1) {
      setLockShow(true);
    } else {
      navigate(
        `/textbook/coursePackage?series_id=${item.series_id}&grade_id=${item.grade_id}&book_id=${item.book_id}`
      );
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    if (isLoading) {
      loadingRef.current = Toast.loading({
        message: "正在获取教材...",
        forbidClick: false,
        duration: 0
      });
    }
    return () => {
      if (loadingRef.current) {
        loadingRef.current.clear();
        loadingRef.current = null;
      }
    };
  }, [isLoading]);
  return (
    <div className={styles["level-container"]}>
      <CommonNavBar
        title={title}
        backgroundColor={"#fff"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      {dataList.length > 0 ? (
        <Tabs
          ellipsis={false}
          className={styles["tab-container"]}
          defaultActive={defaultIndex}
          onChange={(index) => {
            setActiveIndex(index);
            navigate(
              `${location.pathname}?id=${searchParams.get(
                "id"
              )}&active=${index}`,
              {
                replace: true
              }
            );
          }}
          type="line"
        >
          {dataList.map((i) => (
            <Tabs.TabPane key={i.id} title={i.title}>
              {i.grades.length > 0 ? (
                <div className={styles["level-data"]}>
                  {dataList[activeIndex].grades.map((j, jIndex) => (
                    <div
                      className={styles["level-data-item"]}
                      key={`level-grades-item-${dataList[activeIndex].id}-${jIndex}`}
                    >
                      <div
                        className={styles["item-left"]}
                        onClick={() => handleDetail(j)}
                      >
                        <img
                          className={styles["item-left-pic"]}
                          src={j.cover}
                        />
                        {j.is_locked === 1 ? (
                          <div className={styles["item-left-lock"]}>
                            <img
                              className={styles["lock-img"]}
                              src={require("@/images/image/ic_locked.png")}
                            />
                            <div className={styles["lock-text"]}>暂未开放</div>
                          </div>
                        ) : null}
                        <div className={styles["item-left-content"]}>
                          <div className={styles["content-title"]}>
                            {title || ""}
                          </div>
                          <div className={styles["content-text"]}>
                            {j.title || ""}
                          </div>
                        </div>
                      </div>
                      {j.is_locked !== 1 ? (
                        <div className={styles["item-right"]}>
                          {j.units_finished === j.total_units ? (
                            <img
                              className={styles["item-right-pro"]}
                              src={require("@/assets/icon/ic_book_list_finish.png")}
                            />
                          ) : (
                            <ProportionIcon
                              percent={
                                typeof Number(
                                  j.units_finished / j.total_units
                                ) !== NaN
                                  ? Number(j.units_finished / j.total_units)
                                  : 0
                              }
                            />
                          )}
                          <img
                            className={styles["item-right-favorite"]}
                            src={
                              j.favorite
                                ? require("@/assets/icon/ic_book_list_like.png")
                                : require("@/assets/icon/ic_book_list_unlike.png")
                            }
                            onClick={() => handleFavorite(j)}
                          />
                        </div>
                      ) : null}
                    </div>
                  ))}
                  <div className={styles["bottom-text"]}>已经到底啦～</div>
                </div>
              ) : null}
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : null}
      <CommonModal isOpen={lockShow}>
        <div className={styles["pop-container--lock"]}>
          <div className={styles["lock-wrapper"]}>
            <div className={styles["lock-wrapper-title"]}>温馨提示</div>
            <div className={styles["lock-wrapper-text"]}>
              <div>教材暂未开放</div>
              <div>可以先学习其他课程哦~</div>
            </div>
            <div
              className={styles["lock-confirm"]}
              onClick={() => setLockShow(false)}
            >
              确认
            </div>
          </div>
          <img
            onClick={() => setLockShow(false)}
            className={styles["lock-close"]}
            src={require("@/images/mine/recommend-officer/button_cancel.png")}
          />
        </div>
      </CommonModal>
    </div>
  );
};
export default Level;
