import { useEffect, useState } from "react";
import styles from "./PopView.module.less";
import CommonModal from "views/components/CommonModal";
import { getLayer } from "api/home";
import { useNavigate } from "react-router-dom";
const PopView = () => {
  const navigate = useNavigate();
  const [layerData, setLayerData] = useState(null);
  const init = async () => {
    const res = await getLayer();
    if (res.code === 200) {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo || !userInfo.phone_number) {
          return;
        }
        const layerTime = JSON.parse(localStorage.getItem("layerTime"));
        const today = new Date();
        if (
          layerTime &&
          layerTime[userInfo.phone_number] &&
          layerTime[userInfo.phone_number] ===
            `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        ) {
          return;
        }
        if (res.data.image_url) {
          setLayerData(res.data);
          const newLayerTime =
            JSON.parse(localStorage.getItem("layerTime")) || {};
          newLayerTime[userInfo.phone_number] = `${today.getFullYear()}-${
            today.getMonth() + 1
          }-${today.getDate()}`;
          localStorage.setItem("layerTime", JSON.stringify(newLayerTime));
        }
      } catch {
        console.log("处理弹窗数据失败");
      }
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <CommonModal isOpen={!!layerData}>
      <div
        className={styles["pop-view"]}
        onClick={() => {
          setLayerData(null);
        }}
      >
        <img
          className={styles["pop-img"]}
          src={layerData ? layerData.image_url : ""}
          onClick={(e) => {
            e.stopPropagation();
            if (layerData && layerData.link_url) {
              navigate(layerData.link_url);
            }
          }}
        />
        <img
          className={styles["pop-close"]}
          src={require("@/images/image/home-close.png")}
          onClick={(e) => {
            e.stopPropagation();
            setLayerData(null);
          }}
        />
      </div>
    </CommonModal>
  );
};
export default PopView;
