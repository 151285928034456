import React, { useState, useEffect } from "react";
import { Image, Skeleton } from "react-vant";
import { Photo } from "@react-vant/icons";
import { useNavigate } from "react-router-dom";
import "./Recommended.less";
import { getRecommended } from "api/home";
import CommonModal from "views/components/CommonModal";
const ic_data_show_collect = require("@/assets/icon/ic_data_show_collect.png");
function Recommended() {
  const [recommended, setRecommended] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popVisible, setPopVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const result = await getRecommended();
      setLoading(false);
      setRecommended(result.data);
    };

    fetchData();
  }, []);

  return (
    <div className="recommended">
      {loading ? (
        <Skeleton title />
      ) : (
        <div className="recommend-box">
          {recommended.map((item, index) => (
            <div
              className="recommend-box-item"
              key={`recommend-item-${index}`}
              onClick={() => {
                if (item.link === true) {
                  navigate(`/textbook/level?id=${item.id}`);
                } else {
                  setPopVisible(true);
                }
              }}
            >
              <Image
                src={item.cover}
                showLoading={false}
                fit="cover"
                loadingIcon={<Photo src={item.cover + "-thumb"} />}
              />
              <div className="series-slide-text">
                <div>
                  <div className="tj">{item.tags.map((i) => i + " ")}</div>
                  <div className="xl">{item.title}</div>
                </div>
                <div className="series-swiper-bottom">
                  <div className="favorite">
                    <div>{`${item.favorites}+`}</div>
                    <img className="xin" src={ic_data_show_collect} />
                  </div>
                  <div className="study-count">{`${item.studyCount}+学习`}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <CommonModal isOpen={popVisible}>
        <div className="recommended-pop-box">
          <img
            className="recommended-pop-box-cancel"
            onClick={() => setPopVisible(false)}
            src={require("@/images/image/recommended-cancel.png")}
          />
          <div className="recommended-pop-box-text">
            “对不起，您所在机构未开通此教材 请从教材页面进入选择课程”
          </div>
        </div>
      </CommonModal>
    </div>
  );
}

export default Recommended;
