import request from "utils/request";

export async function getBanner() {
  return request.get("/home/banner.php");
}

export async function getRecommended() {
  return request.get("/home/recommended.php", "nocache");
}

export async function getTeacherList() {
  return request.get("/home/teacherList.php");
}

export async function getRecentLesson() {
  return request.get("/home/recentLesson.php");
}

export async function getTodayLesson() {
  return request.get("/home/todayLesson.php", "nocache");
}

export async function getLastAsset() {
  return request.get("/lesson/lastAsset.php", "nocache");
}

// 获取首页收藏的课件
export const getFavoriteList = () => request.get("/materials/favoriteCourseware.php", "nocache");

export const getLayer = () => {
  return request.get("/home/layer.php", "nocache");
}