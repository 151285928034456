import styles from "./StudyTogether.module.less";
import { Wechat } from "@react-vant/icons";

function StudyTogetherWeb() {
  return (
    <div className={styles["study-container"]}>
      <div className={styles["back-wrapper"]}></div>
      <div className={styles["top"]}>
        <div className={styles["top-left"]}>
          <div className={styles["top-left-text"]}>欢迎扫码</div>
          <div className={styles["top-left-text"]}>添加口袋领航外教官方客服</div>
          <div className={styles["top-left-icon"]}>
            <div className={styles["left-circular"]}></div>
            <div className={styles["right-circular"]}></div>
          </div>
        </div>
        <img
            className={styles["top-right"]}
            src={require("@/images/mine/study-together/u_bao.png")}
        />
      </div>
      <div className={styles["center"]}>
        <div className={styles["center-text"]}>长按/截图 保存图片</div>
        <div className={styles["center-text"]}>
          <span>打开微信</span>
          <Wechat color="#43C93E"/>
          <span>扫码添加</span>
        </div>
        {/*<img*/}
        {/*  className={styles["center-code"]}*/}
        {/*  src={`${process.env.REACT_APP_API_CDN}/static/banner-daka0830.png`}*/}
        {/*/>*/}
        <img className={styles["center-code"]} src={"https://cdn03.myaitalk.vip/static/kf.png"}></img>
        <div className={styles["center-phone"]}>
          {"如扫码失败，请点击APP首页“在线客服”图标获取帮助"}
        </div>
        {/*<div className={styles["center-phone"]}>*/}
        {/*  {"工作时间：周一到周五，8:30-5:30"}*/}
        {/*</div>*/}
      </div>
      <div className={styles["bottom"]}>
        <div className={styles["bottom-item"]}>
        <img
            className={styles["item-icon"]}
            src={require("@/images/mine/study-together/icon_1.png")}
          />
          <div className={styles["item-text"]}>结伴学习</div>
          <div className={styles["item-text"]}>互动交流</div>
        </div>
        <div className={styles["bottom-item"]}>
          <img
            className={styles["item-icon"]}
            src={require("@/images/mine/study-together/icon_2.png")}
          />
          <div className={styles["item-text"]}>优先体验</div>
          <div className={styles["item-text"]}>最新功能</div>
        </div>
        <div className={styles["bottom-item"]}>
          <img
            className={styles["item-icon"]}
            src={require("@/images/mine/study-together/icon_3.png")}
          />
          <div className={styles["item-text"]}>优惠福利</div>
          <div className={styles["item-text"]}>快速获得</div>
        </div>
      </div>
    </div>
  );
}
export default StudyTogetherWeb;
