import { useNavigate } from "react-router-dom";
import styles from "./MyOrder.module.less";
import CommonNavBar from "views/components/CommonNavBar";
import { ArrowDown, ArrowUp } from "@react-vant/icons";
import { useState, useEffect, useRef } from "react";
import { Toast } from "react-vant";
import { getMyProductListV2 } from "api/product";
import useScrollLoad from "utils/useScrollLoad";

const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};

const MyOrder = () => {
  const [orderData, setOrderData] = useState([]);
  const loadingRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(true);

  const initData = async () => {
    loadingRef.current = Toast.loading({
      message: "正在加载数据...",
      forbidClick: true,
      duration: 0
    });
    setLoading(true);
    const res = await getMyProductListV2(currentPage);
    loadingRef.current.clear();
    setLoading(false);
    if (res.code === 200) {
      if (res.data.length === 0) {
        setHasNext(false);
      }
      setCurrentPage(currentPage + 1);
      res.data.forEach((item) => {
        item.open = false;
      });
      setOrderData([...orderData, ...res.data]);
    }
  };
  const containerRef = useScrollLoad(() => {
    if (!loading && hasNext) {
      initData();
    }
  });
  useEffect(() => {
    initData();
    return () => {
      try {
        if (loadingRef.current) {
          loadingRef.current.clear();
          loadingRef.current = null;
        }
      } catch (e) {
        console.log(e);
      }
    };
  }, []);
  const navigate = useNavigate();
  return (
    <div className={styles["my-order-container"]}>
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <CommonNavBar
        title={"我的订单"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <div className={styles["order-list"]} ref={containerRef}>
        {orderData.map((item, index) => (
          <div
            className={styles["order-list-item"]}
            key={`order-item-${index}`}
          >
            <div className={styles["item-header"]}>
              <div className={styles["item-header-left"]}>
                {item.product_name || ""}
              </div>
              <div className={styles["item-header-right"]}>
                {item.order_status || ""}
              </div>
            </div>
            <div className={styles["item-body"]}>
              {item.thumb ? (
                <img
                  className={styles["item-body-pic"]}
                  src={`${process.env.REACT_APP_API_CDN}${item.thumb}`}
                />
              ) : null}

              <div className={styles["item-body-text"]}>
                <div className={styles["text-item"]}>{`数量：${
                  item.quantity | "--"
                }课时`}</div>
                <div className={styles["text-item"]}>{`有效期：${
                  item.month_day || "--"
                }`}</div>
                <div className={styles["text-item"]}>{`备注：${
                  item.notes || "无"
                }`}</div>
              </div>
            </div>
            <div className={styles["item-footer-amount"]}>
              <div className={styles["amount-money"]}>
                {`¥${item.total_amount || "0.00"}`}
              </div>
              {item.order_status === "已支付" ? (
                <div
                  className={styles["amount-button"]}
                  onClick={() => {
                    navigate(
                      `/webUrlView?webUrl=${encodeURIComponent(
                        `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
                      )}&title=${encodeURIComponent("课程服务协议")}`
                    );
                  }}
                >
                  查看协议
                </div>
              ) : (
                <div className={styles["amount-status"]}>
                  {item.refund_status || "--"}
                </div>
              )}
            </div>
            <div className={styles["item-footer-cell"]}>
              <div className={styles["cell"]}>
                <div className={styles["cell-order"]}>
                  <span>订单编号</span>
                  <span className={styles["cell-order-number"]}>
                    {item.order_no || "--"}
                  </span>
                </div>
                <div
                  className={styles["cell-copy"]}
                  onClick={() => {
                    try {
                      const orderNo = document.createElement("textarea");
                      orderNo.id = "orderNo";
                      orderNo.value = item.order_no || "";
                      document.body.appendChild(orderNo);
                      orderNo.select();
                      orderNo.setSelectionRange(0, 99999);
                      document.execCommand("copy");
                      document.body.removeChild(orderNo);
                      Toast.info("已复制到剪贴板！");
                    } catch (err) {
                      Toast.fail("复制失败：" + err);
                    }
                  }}
                >
                  复制
                </div>
              </div>
              {item.open ? (
                <>
                  <div className={styles["cell"]}>
                    <div className={styles["cell-title"]}>支付方式</div>
                    <div className={styles["cell-text"]}>
                      {item.pay_method || "--"}
                    </div>
                  </div>
                  <div className={styles["cell"]}>
                    <div className={styles["cell-title"]}>支付渠道</div>
                    <div className={styles["cell-text"]}>
                      {item.pay_channel || "--"}
                    </div>
                  </div>
                  <div className={styles["cell"]}>
                    <div className={styles["cell-title"]}>支付时间</div>
                    <div className={styles["cell-text"]}>
                      {item.payment_date || "--"}
                    </div>
                  </div>
                  <div className={styles["cell"]}>
                    <div className={styles["cell-title"]}>有效期至</div>
                    <div className={styles["cell-text"]}>
                      {item.expiration_date || "--"}
                    </div>
                  </div>
                  <div className={styles["cell"]}>
                    <div className={styles["cell-title"]}>消耗课时</div>
                    <div className={styles["cell-text"]}>{`${
                      item.expend_asset | "--"
                    }课时`}</div>
                  </div>
                  <div className={styles["cell"]}>
                    <div className={styles["cell-title"]}>剩余课时</div>
                    <div className={styles["cell-text"]}>{`${
                      item.surplus_asset | "--"
                    }课时`}</div>
                  </div>
                  {item.order_status !== "已支付" ? (
                    <>
                      <div className={styles["cell"]}>
                        <div className={styles["cell-title"]}>退款申请日期</div>
                        <div className={styles["cell-text"]}>
                          {item.refund_create_date || "--"}
                        </div>
                      </div>
                      <div className={styles["cell"]}>
                        <div className={styles["cell-title"]}>退款金额</div>
                        <div className={styles["cell-text"]}>{`¥${
                          item.refund_amount || "0.00"
                        }`}</div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              {!item.open ? (
                <div
                  className={styles["more-button"]}
                  onClick={() => {
                    orderData[index].open = !orderData[index].open;
                    setOrderData([...orderData]);
                  }}
                >
                  <span className={styles["more-button-text"]}>
                    查看更多订单信息
                  </span>
                  <ArrowDown className={styles["more-button-icon"]} />
                </div>
              ) : (
                <div
                  className={styles["more-button"]}
                  onClick={() => {
                    orderData[index].open = !orderData[index].open;
                    setOrderData([...orderData]);
                  }}
                >
                  <span className={styles["more-button-text"]}>
                    收起订单信息
                  </span>
                  <ArrowUp className={styles["more-button-icon"]} />
                </div>
              )}
            </div>
          </div>
        ))}
        <div className={styles["order-list-bottom"]}>
          {loading
            ? loadingText["loading"]
            : orderData.length === 0
            ? loadingText["empty"]
            : loadingText["nomore"]}
        </div>
      </div>
    </div>
  );
};

export default MyOrder;
