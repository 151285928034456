import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./WordCollection.module.less";
import { Checkbox, Toast, Dialog } from "react-vant";
import CommonNavBar from "views/components/CommonNavBar";
import CommonButton from "views/components/CommonButton";
import { Star } from "@react-vant/icons";
import { getWordList, wordAudio, deleteWord } from "@/api/wordCollection";
function UserReport() {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("ASC");
  const [wordList, setWordList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(null);
  const [selectShow, setSelectShow] = useState(false);
  const audioRef = useRef(null);
  async function handlePlayAudio(index, type) {
    audioRef.current.pause();
    if (currentPlay !== `${index}-${type}`) {
      if (wordList[index][`audio_${type}`]) {
        audioRef.current.src = wordList[index][`audio_${type}`];
      } else {
        Toast.loading({
          message: "音频加载中...",
          forbidClick: true,
          duration: 0
        });
        const audioRes = await wordAudio({
          word: wordList[index].definition.word,
          type: type
        });
        Toast.clear();
        wordList[index][
          `audio_${type}`
        ] = `data:audio/wav;base64,${audioRes.data}`;
        audioRef.current.src = `data:audio/wav;base64,${audioRes.data}`;
        setWordList([...wordList]);
      }
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setCurrentPlay(`${index}-${type}`);
    }
  }
  async function getData() {
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0
    });
    const res = await getWordList(orderBy);
    Toast.clear();
    if (res.code === 200) {
      res.data.forEach((item) => {
        try {
          item.definition = JSON.parse(item.definition);
          item.definition.translation = item.definition.translation.replace(
            /\n/g,
            "<br>"
          );
          if (item.word.split(" ").length > 1) {
            item.isSentence = true;
          } else {
            item.isSentence = false;
          }
        } catch {
          console.log("单条数据处理错误");
        }
        try {
          item.collection_time = `${
            item.collection_time.split(" ")[0].split("-")[0]
          }年${item.collection_time.split(" ")[0].split("-")[1]}月${
            item.collection_time.split(" ")[0].split("-")[2]
          }日`;
        } catch {
          item.collection_time = "";
          console.log("单条时间处理错误");
        }
      });
      setWordList(res.data);
    }
  }
  async function handleDelete(item) {
    try {
      await Dialog.confirm({
        message: `是否取消收藏单词 ${item.word}`,
        onCancel: () => console.log("cancel"),
        onConfirm: () => {
          deleteWord(item.id).then((res) => {
            if (res.code === 200) {
              getData();
            }
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  function determineCheckAll() {
    if (
      wordList.filter((item) => !item.checked).length === 0 &&
      wordList.length !== 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }
  const handleExport = () => {
    if (wordList.filter((item) => item.checked).length === 0) {
      Toast.info("请选择单词");
    } else {
      navigate(
        `/mine/exportPreview?ids=${encodeURIComponent(
          JSON.stringify(
            wordList.filter((item) => item.checked).map((item) => item.id)
          )
        )}`
      );
    }
  };
  useEffect(() => {
    audioRef.current = new Audio();
    function handleStop() {
      audioRef.current.src = "";
      setCurrentPlay(null);
    }
    audioRef.current.addEventListener("pause", handleStop);
    audioRef.current.addEventListener("ended", handleStop);
    return () => {
      handleStop();
      audioRef.current.pause();
      audioRef.current.removeEventListener("pause", handleStop);
      audioRef.current.removeEventListener("ended", handleStop);
    };
  }, []);
  useEffect(() => {
    setSelectAll(false);
    getData();
  }, [orderBy]);
  return (
    <div className={styles["word-collection-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
        title="单词收藏"
        backgroundColor={"#f4f6ff"}
      />
      <div className={styles["sort-tabs"]}>
        <div
          className={`${styles["sort-tabs-item"]} ${
            orderBy === "ASC" ? styles["sort-tabs-item--select"] : ""
          }`}
          onClick={() => setOrderBy("ASC")}
        >
          按时间顺序
        </div>
        <div
          className={`${styles["sort-tabs-item"]} ${
            orderBy === "DESC" ? styles["sort-tabs-item--select"] : ""
          }`}
          onClick={() => setOrderBy("DESC")}
        >
          按时间倒序
        </div>
        <div
          className={`${styles["sort-tabs-item"]} ${
            orderBy === "RAND" ? styles["sort-tabs-item--select"] : ""
          }`}
          onClick={() => setOrderBy("RAND")}
        >
          按时间乱序
        </div>
      </div>
      <div className={styles["word-list"]}>
        {wordList.map((item, index) => (
          <div key={`wordList-${index}-${orderBy}`}>
            <div className={styles["word-list-time"]}>
              {item.collection_time}
            </div>
            <div className={styles["word-list-item"]}>
              <div className={styles["item-header"]}>
                <span className={styles["item-header-text"]}>{item.word}</span>
                {selectMode ? (
                  <div className={styles["item-header-select"]}>
                    <Checkbox
                      checked={item.checked}
                      onChange={(v) => {
                        item.checked = v;
                        setWordList([...wordList]);
                        determineCheckAll();
                      }}
                    ></Checkbox>
                  </div>
                ) : (
                  <Star
                    className={styles["item-header-icon"]}
                    color="rgba(255, 195, 0, 1)"
                    fontSize="16px"
                    onClick={() => handleDelete(item)}
                  />
                )}
              </div>
              <div className={styles["item-audio"]}>
                {!item.isSentence ? (
                  <>
                    <span
                      className={styles["item-audio-text"]}
                      onClick={() => handlePlayAudio(index, 1)}
                    >{`英/${item.definition.british}/`}</span>
                    <img
                      className={styles["item-audio-icon"]}
                      onClick={() => handlePlayAudio(index, 1)}
                      src={
                        currentPlay === `${index}-1`
                          ? require("@/images/image/audio-play.png")
                          : require("@/images/image/audio-unplay.png")
                      }
                    />
                    <span
                      className={styles["item-audio-text"]}
                      onClick={() => handlePlayAudio(index, 2)}
                    >{`美/${item.definition.phonetic}/`}</span>
                  </>
                ) : null}
                <img
                  className={styles["item-audio-icon"]}
                  onClick={() => handlePlayAudio(index, 2)}
                  src={
                    currentPlay === `${index}-2`
                      ? require("@/images/image/audio-play.png")
                      : require("@/images/image/audio-unplay.png")
                  }
                />
              </div>
              <div className={styles["item-content"]}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.definition.translation
                  }}
                />
              </div>
              <div className={styles["item-content"]}>
                <div>例句:</div>
                {Array.isArray(item.definition.detail)
                  ? item.definition.detail.map((detailItem, detailIndex) => {
                      return detailIndex < 3 ? (
                        <div key={`wordCollection-detail-${detailIndex}`}>
                          {detailItem}
                        </div>
                      ) : null;
                    })
                  : null}
              </div>
            </div>
          </div>
        ))}
      </div>
      {!selectMode ? (
        <div className={styles["bottom-wrapper--unselect"]}>
          <CommonButton
            delay={500}
            onClick={() => setSelectMode(true)}
            text="导出生词本"
          />
        </div>
      ) : (
        <div className={styles["bottom-wrapper--select"]}>
          <div className={styles["check-wrapper"]}>
            <Checkbox
              checked={selectAll}
              onChange={(v) => {
                setSelectAll(v);
                wordList.forEach((item) => {
                  item.checked = v;
                });
                setWordList([...wordList]);
              }}
            >
              {`全选（已选${wordList.filter((item) => item.checked).length}）`}
            </Checkbox>
            <div
              className={styles["check-wrapper-see"]}
              onClick={() => setSelectShow(true)}
            >
              查看
            </div>
          </div>
          <CommonButton
            className={styles["export-button"]}
            delay={500}
            onClick={handleExport}
            text="导出"
          />
        </div>
      )}
      {selectShow ? (
        <div className={styles["select-pop"]}>
          <div className={styles["select-pop-bottom"]}>
            <div className={styles["header-title"]}>已选单词</div>
            <div className={styles["content"]}>
              {wordList
                .filter((v) => v.checked)
                .map((item, index) => (
                  <div
                    className={styles["content-item"]}
                    key={`wordCollection-item-${index}`}
                    onClick={() => {
                      item.checked = false;
                      setWordList([...wordList]);
                    }}
                  >
                    <span>{item.word}</span>
                    <img
                      className={styles["content-item-icon"]}
                      src={require("@/images/mine/ic_words_delete.png")}
                    />
                  </div>
                ))}
            </div>
            <div className={styles["button-wrapper"]}>
              <CommonButton
                className={styles["export-button"]}
                delay={500}
                onClick={handleExport}
                text="直接导出"
              />
              <CommonButton
                className={styles["close-button"]}
                delay={500}
                onClick={() => setSelectShow(false)}
                text="关闭弹窗，继续添加"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default UserReport;
